@charset "UTF-8";
@import url("reset.css");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

:root {
  --brand-backgorund: #2980b9;
  --brand-color: #ecf0f1;
  --aside-button-hover: #060f2c;
  --header-background: #ffffff;
  --aside-background: #040E26;
  --brand-blue: #040E26;
  --brand-orange: #FC5E28;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0 10px 0;
  width: 100%;
}

body {
  font-family: Calibri, sans-serif;
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  overflow-x: auto;
}

header.mainHeader {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  gap: 10px;
}
header.mainHeader > div {
  flex: 1 !important;
  height: 40px;
  display: flex;
  align-items: center;
}
header.mainHeader > div label {
  flex: 1;
}
header.mainHeader a, header.mainHeader button {
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: transparent;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  transition: color 0.3s;
  white-space: nowrap;
}
header.mainHeader a.profileButton, header.mainHeader button.profileButton {
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  padding: 0 20px;
  transition: background-color 0.3s;
}
header.mainHeader a.profileButton:hover, header.mainHeader button.profileButton:hover {
  background-color: var(--brand-orange);
  color: #FFFFFF;
}
header.mainHeader a > svg, header.mainHeader button > svg {
  font-size: 30px;
}
header.mainHeader a:hover, header.mainHeader button:hover {
  color: #2980b9;
}
header.panelHeader {
  background-color: var(--header-background);
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: auto;
}
header.panelHeader > ul {
  height: 4rem;
  display: flex;
  gap: 5px;
  align-items: center;
}
header.panelHeader > ul > li > a, header.panelHeader > ul > li > button {
  cursor: pointer;
  background: transparent;
  font-size: 22px;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  gap: 5px;
  color: var(--brand-blue);
  transition: color 0.3s;
}
header.panelHeader > ul > li > a:hover, header.panelHeader > ul > li > button:hover {
  color: var(--brand-orange);
}
header.panelHeader > ul > li > a span, header.panelHeader > ul > li > button span {
  font-size: 14px;
  white-space: nowrap;
}
header.panelHeader > ul > li > a img, header.panelHeader > ul > li > button img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 20px;
}
header.panelHeader > ul > li .dropdown-menu {
  position: absolute;
  padding-top: 10px;
  top: 56px;
  right: 0;
  min-width: 200px;
  overflow: hidden;
  border-radius: 5px;
  transition: 0.3s;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
}
header.panelHeader > ul > li .dropdown-menu.dropdown-menu-visible {
  opacity: 1;
  visibility: visible;
}
header.panelHeader > ul > li .dropdown-menu li a, header.panelHeader > ul > li .dropdown-menu li button {
  display: block;
  white-space: nowrap;
  padding: 10px;
  color: #fff;
  z-index: 99;
  text-decoration: none;
  transition: 0.3s;
  background-color: #040E26;
  text-align: center;
  text-transform: initial;
  cursor: pointer;
}
header.panelHeader > ul > li .dropdown-menu li a:hover, header.panelHeader > ul > li .dropdown-menu li button:hover {
  background-color: #FC5E28;
  color: white;
}
header.panelHeader > ul > li .dropdown-menu li button {
  border-radius: 0;
  width: 100%;
}
header.panelHeader > ul > li .dropdown-menu li:first-child a {
  border-radius: 5px 5px 0 0;
}

.loadingScreen {
  position: fixed;
  inset: 0;
  z-index: 2000;
  background-color: var(--brand-backgorund);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.loadingScreen.active {
  transition: 0s;
  visibility: visible;
  opacity: 1;
}
.loadingScreen img:first-child {
  height: 15vh;
}
.loadingScreen img:last-child {
  height: 5vh;
}

aside {
  background-color: var(--aside-background);
  width: 15rem;
  transition: 0.3s;
}
aside .logo {
  height: 64px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #1A2849;
}
aside .logo a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex: 1;
}
aside .logo a img {
  width: 100%;
  max-height: 70%;
  object-fit: contain;
}
aside .logo button {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  background: transparent;
  color: var(--brand-orange);
  font-size: 25px;
  transition: color 0.3s;
}
aside .logo button:hover {
  color: white;
}
aside nav {
  height: calc(100vh - 4rem);
  overflow-y: auto;
}
aside nav > ul {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
aside nav > ul > li.active > a > svg, aside nav > ul > li.active section > svg {
  transform: rotate(90deg);
}
aside nav > ul > li > a, aside nav > ul > li section {
  display: flex;
  align-items: center;
  color: white;
  gap: 20px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}
aside nav > ul > li > a:hover, aside nav > ul > li section:hover {
  background-color: var(--aside-button-hover);
}
aside nav > ul > li > a em, aside nav > ul > li section em {
  color: var(--brand-orange);
  font-size: 1.5rem;
}
aside nav > ul > li > a span, aside nav > ul > li section span {
  flex: 1;
  font-size: 0.9rem;
  display: flex;
}
aside nav > ul > li > a > svg, aside nav > ul > li section > svg {
  font-size: 0.8rem;
  transition: transform 0.3s;
}
aside nav > ul > li ul {
  background-color: #0c1a41;
  overflow: hidden;
  display: none;
  flex-direction: column;
  row-gap: 5px;
}
aside nav > ul > li ul li a {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  color: white;
  gap: 20px;
  padding: 10px;
  transition: background-color 0.3s;
}
aside nav > ul > li ul li a:hover {
  background-color: var(--aside-button-hover);
}
aside nav > ul > li ul li a svg {
  color: var(--brand-orange);
  font-size: 20px;
}

.myCards {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.myCards .myCard {
  display: flex;
  justify-content: space-between;
  flex: 1;
  position: relative;
  border: 1px solid #ddd;
  overflow: hidden;
  transition: 0.3s all;
  min-width: 250px;
  min-height: 150px;
}
.myCards .myCard:hover {
  flex: 2;
}
.myCards .myCard:hover .cardInfo {
  opacity: 1;
}
.myCards .myCard section {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 5px;
}
.myCards .myCard section h4 {
  font-size: 30px;
  text-align: center;
}
.myCards .myCard section span {
  font-size: 16px;
  opacity: 0.8;
}

.HomeContent {
  flex: 1;
}

.HomeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.PanelContainer {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  flex: 1;
  min-height: 100vh;
}
.form form {
  width: 100%;
  max-width: 500px;
  box-shadow: 8px 8px 30px 0 rgba(42, 67, 113, 0.15);
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.form form h2 {
  text-align: center;
  font-size: 50px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.form form section {
  display: flex;
  justify-content: flex-end;
}
.form form section span {
  color: #444444;
  padding: 5px;
}
.form form section span a {
  color: var(--brand-backgorund);
  white-space: nowrap;
}

.hakkimizda {
  margin: 50px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.hakkimizda h2 {
  font-size: 30px;
  font-weight: bold;
  color: #2980b9;
  text-align: center;
}
.hakkimizda p {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}
.questions h3 {
  font-size: 30px;
  font-weight: bold;
  color: #2980b9;
  text-align: center;
}
.questions .question {
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;
}
.questions .question.active {
  height: auto;
}
.questions .question h2 {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-weight: normal;
  transition: background-color 0.3s;
}
.questions .question h2:hover {
  background: #f5f5f5;
}
.questions .question section {
  padding: 0 20px;
  display: none;
  overflow: hidden;
}
.questions .question section p {
  padding: 20px;
}
.questions .question ul {
  list-style: decimal;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.questions .question ul li {
  font-size: 20px;
}

.devices {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}
.devices h3 {
  font-size: 30px;
  font-weight: bold;
  color: #2980b9;
  text-align: center;
}
.devices .device {
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
}
.devices .device h2 {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-weight: normal;
  gap: 15px;
  transition: background-color 0.3s;
}
.devices .device h2 svg {
  color: #2980b9;
}
.devices .device h2:hover {
  background: #f5f5f5;
}
.devices .device section {
  padding: 0 20px;
  display: none;
  overflow: hidden;
}
.devices .device section ul {
  padding: 20px 0 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.devices .device section ul li {
  font-size: 20px;
}
.devices .device section ul li:before {
  content: "•";
  color: var(--brand-backgorund);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.widgets {
  padding: 15px;
}
.widgets > div > div {
  padding: 0 !important;
}
.widgets .widget {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 20px;
  cursor: default;
  transition: box-shadow 0.3s;
}
.widgets .widget .widgetHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.widgets .widget .widgetHeader svg {
  width: 50px;
  height: 50px;
  color: #0160E7;
}
.widgets .widget .widgetHeader h3 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}
.widgets .widget .widgetContent p {
  text-align: center;
}
.widgets .widget:hover {
  box-shadow: 8px 8px 30px 0 rgba(42, 67, 113, 0.15);
}

.topBar {
  height: 40px;
  background-color: var(--brand-backgorund);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.topBar section {
  display: flex;
  align-items: center;
  gap: 10px;
}
.topBar section a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
}

.cardEditBar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardEditBar button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--brand-backgorund);
  color: var(--brand-color);
  height: 40px;
  padding: 0 25px;
  cursor: pointer;
  flex: 1;
}

.userCards .addCard {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}
.userCards .addCard button, .userCards .addCard a {
  color: var(--brand-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s background-color, 0.3s color;
  border-radius: 5px;
  outline: none !important;
  background-color: var(--brand-backgorund);
  border: 1px solid var(--brand-backgorund);
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}
.userCards .addCard button svg, .userCards .addCard a svg {
  color: var(--brand-color);
  font-size: 20px;
  transition: color 0.3s;
  flex-shrink: 0;
}
.userCards .addCard button:hover, .userCards .addCard a:hover {
  background-color: var(--brand-color);
  color: var(--brand-backgorund);
}
.userCards .addCard button:hover svg, .userCards .addCard a:hover svg {
  color: var(--brand-backgorund);
}
.userCards .userCard {
  position: relative;
  padding-top: calc(100% * 9 / 16);
  display: block;
  margin-bottom: 20px;
}
.userCards .userCard section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userCards .userCard section .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
}
.userCards .userCard section .info h2 {
  font-size: 25px;
}
.userCards .userCard section .info i {
  opacity: 0.8;
  font-size: 17px;
}
.userCards .userCard section .buttons {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--brand-backgorund);
}
.userCards .userCard section .buttons button, .userCards .userCard section .buttons a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}
.userCards .userCard section .buttons button:hover, .userCards .userCard section .buttons a:hover {
  background-color: var(--brand-color);
  color: var(--brand-backgorund);
}

.move {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.move .loading {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.move .loading img {
  height: 90%;
}
.move button {
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  border: none;
  padding: 10px;
  font-size: 25px;
  cursor: pointer;
  transition: transform 0.3s;
}
.move button:hover {
  transform: scale(1.3);
}

.cardWrapper {
  min-height: 100vh;
}

.input:not(textarea) {
  height: 40px;
}

.qrMenuWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 9;
}
.qrMenuWrapper .qrMenuCloseBack {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
}
.qrMenuWrapper .qrMenu {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 70vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 11;
}
.qrMenuWrapper .qrMenu .qrCode {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qrMenuWrapper .qrMenu .qrCode .qrCodeText {
  font-size: 20px;
  font-weight: bold;
}

.MobileMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: var(--brand-backgorund);
  z-index: 99;
}
.MobileMenu > ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.MobileMenu > ul > li {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MobileMenu > ul > li > a, .MobileMenu > ul > li > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: transparent;
  text-align: center;
  padding: 10px 0;
  gap: 5px;
  cursor: pointer;
}
.MobileMenu > ul > li > a svg, .MobileMenu > ul > li > button svg {
  font-size: 25px;
}
.MobileMenu > ul > li > a span, .MobileMenu > ul > li > button span {
  font-size: 10px;
}
.MobileMenu > ul > li ul {
  position: fixed;
  bottom: 60px;
  width: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.MobileMenu > ul > li ul.dropdown-menu-visible {
  opacity: 1;
  visibility: visible;
}
.MobileMenu > ul > li ul li a, .MobileMenu > ul > li ul li button {
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  display: block;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: color 0.3s, background-color 0.3s;
}
.MobileMenu > ul > li ul li a:hover, .MobileMenu > ul > li ul li button:hover {
  background-color: var(--brand-orange);
  color: var(--brand-blue);
}

.table, table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  overflow-x: auto;
  font-size: 16px;
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.table div .tr, .table div tr, .table thead .tr, .table thead tr, .table tbody .tr, .table tbody tr, table div .tr, table div tr, table thead .tr, table thead tr, table tbody .tr, table tbody tr {
  display: flex;
  background-color: #FFFFFF;
  transition: background-color 0.3s, color 0.3s;
}
.table div .tr.rowColor, .table div tr.rowColor, .table thead .tr.rowColor, .table thead tr.rowColor, .table tbody .tr.rowColor, .table tbody tr.rowColor, table div .tr.rowColor, table div tr.rowColor, table thead .tr.rowColor, table thead tr.rowColor, table tbody .tr.rowColor, table tbody tr.rowColor {
  background-color: #f1f2f6;
}
.table div .tr:hover, .table div tr:hover, .table thead .tr:hover, .table thead tr:hover, .table tbody .tr:hover, .table tbody tr:hover, table div .tr:hover, table div tr:hover, table thead .tr:hover, table thead tr:hover, table tbody .tr:hover, table tbody tr:hover {
  background-color: #ced6e0;
}
.table div .tr .th, .table div .tr th, .table div tr .th, .table div tr th, .table thead .tr .th, .table thead .tr th, .table thead tr .th, .table thead tr th, .table tbody .tr .th, .table tbody .tr th, .table tbody tr .th, .table tbody tr th, table div .tr .th, table div .tr th, table div tr .th, table div tr th, table thead .tr .th, table thead .tr th, table thead tr .th, table thead tr th, table tbody .tr .th, table tbody .tr th, table tbody tr .th, table tbody tr th {
  color: #FFFFFF;
  background-color: var(--brand-blue);
  position: relative;
  flex: 1;
  text-align: center;
  min-height: 40px;
}
.table div .tr .th span, .table div .tr th span, .table div tr .th span, .table div tr th span, .table thead .tr .th span, .table thead .tr th span, .table thead tr .th span, .table thead tr th span, .table tbody .tr .th span, .table tbody .tr th span, .table tbody tr .th span, .table tbody tr th span, table div .tr .th span, table div .tr th span, table div tr .th span, table div tr th span, table thead .tr .th span, table thead .tr th span, table thead tr .th span, table thead tr th span, table tbody .tr .th span, table tbody .tr th span, table tbody tr .th span, table tbody tr th span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.table div .tr .td, .table div .tr td, .table div tr .td, .table div tr td, .table thead .tr .td, .table thead .tr td, .table thead tr .td, .table thead tr td, .table tbody .tr .td, .table tbody .tr td, .table tbody tr .td, .table tbody tr td, table div .tr .td, table div .tr td, table div tr .td, table div tr td, table thead .tr .td, table thead .tr td, table thead tr .td, table thead tr td, table tbody .tr .td, table tbody .tr td, table tbody tr .td, table tbody tr td {
  text-align: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  min-height: 40px;
  font-size: 14px;
  border-right: 1px solid #ddd;
}
.table div .tr .td section, .table div .tr td section, .table div tr .td section, .table div tr td section, .table thead .tr .td section, .table thead .tr td section, .table thead tr .td section, .table thead tr td section, .table tbody .tr .td section, .table tbody .tr td section, .table tbody tr .td section, .table tbody tr td section, table div .tr .td section, table div .tr td section, table div tr .td section, table div tr td section, table thead .tr .td section, table thead .tr td section, table thead tr .td section, table thead tr td section, table tbody .tr .td section, table tbody .tr td section, table tbody tr .td section, table tbody tr td section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  text-align: center;
}
.table div .tr .td section > span, .table div .tr td section > span, .table div tr .td section > span, .table div tr td section > span, .table thead .tr .td section > span, .table thead .tr td section > span, .table thead tr .td section > span, .table thead tr td section > span, .table tbody .tr .td section > span, .table tbody .tr td section > span, .table tbody tr .td section > span, .table tbody tr td section > span, table div .tr .td section > span, table div .tr td section > span, table div tr .td section > span, table div tr td section > span, table thead .tr .td section > span, table thead .tr td section > span, table thead tr .td section > span, table thead tr td section > span, table tbody .tr .td section > span, table tbody .tr td section > span, table tbody tr .td section > span, table tbody tr td section > span {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  width: 100%;
}
.table div .tr .td section:first-child, .table div .tr td section:first-child, .table div tr .td section:first-child, .table div tr td section:first-child, .table thead .tr .td section:first-child, .table thead .tr td section:first-child, .table thead tr .td section:first-child, .table thead tr td section:first-child, .table tbody .tr .td section:first-child, .table tbody .tr td section:first-child, .table tbody tr .td section:first-child, .table tbody tr td section:first-child, table div .tr .td section:first-child, table div .tr td section:first-child, table div tr .td section:first-child, table div tr td section:first-child, table thead .tr .td section:first-child, table thead .tr td section:first-child, table thead tr .td section:first-child, table thead tr td section:first-child, table tbody .tr .td section:first-child, table tbody .tr td section:first-child, table tbody tr .td section:first-child, table tbody tr td section:first-child {
  width: 100px;
  border-right: 1px solid #ddd;
}
.table div .tr .td section:last-child, .table div .tr td section:last-child, .table div tr .td section:last-child, .table div tr td section:last-child, .table thead .tr .td section:last-child, .table thead .tr td section:last-child, .table thead tr .td section:last-child, .table thead tr td section:last-child, .table tbody .tr .td section:last-child, .table tbody .tr td section:last-child, .table tbody tr .td section:last-child, .table tbody tr td section:last-child, table div .tr .td section:last-child, table div .tr td section:last-child, table div tr .td section:last-child, table div tr td section:last-child, table thead .tr .td section:last-child, table thead .tr td section:last-child, table thead tr .td section:last-child, table thead tr td section:last-child, table tbody .tr .td section:last-child, table tbody .tr td section:last-child, table tbody tr .td section:last-child, table tbody tr td section:last-child {
  flex: 1;
}
.table div .tr .td > i, .table div .tr td > i, .table div tr .td > i, .table div tr td > i, .table thead .tr .td > i, .table thead .tr td > i, .table thead tr .td > i, .table thead tr td > i, .table tbody .tr .td > i, .table tbody .tr td > i, .table tbody tr .td > i, .table tbody tr td > i, table div .tr .td > i, table div .tr td > i, table div tr .td > i, table div tr td > i, table thead .tr .td > i, table thead .tr td > i, table thead tr .td > i, table thead tr td > i, table tbody .tr .td > i, table tbody .tr td > i, table tbody tr .td > i, table tbody tr td > i {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table div .tr .td > i:focus, .table div .tr td > i:focus, .table div tr .td > i:focus, .table div tr td > i:focus, .table thead .tr .td > i:focus, .table thead .tr td > i:focus, .table thead tr .td > i:focus, .table thead tr td > i:focus, .table tbody .tr .td > i:focus, .table tbody .tr td > i:focus, .table tbody tr .td > i:focus, .table tbody tr td > i:focus, table div .tr .td > i:focus, table div .tr td > i:focus, table div tr .td > i:focus, table div tr td > i:focus, table thead .tr .td > i:focus, table thead .tr td > i:focus, table thead tr .td > i:focus, table thead tr td > i:focus, table tbody .tr .td > i:focus, table tbody .tr td > i:focus, table tbody tr .td > i:focus, table tbody tr td > i:focus {
  background-color: transparent;
}
.table div .tr .td > span, .table div .tr td > span, .table div tr .td > span, .table div tr td > span, .table thead .tr .td > span, .table thead .tr td > span, .table thead tr .td > span, .table thead tr td > span, .table tbody .tr .td > span, .table tbody .tr td > span, .table tbody tr .td > span, .table tbody tr td > span, table div .tr .td > span, table div .tr td > span, table div tr .td > span, table div tr td > span, table thead .tr .td > span, table thead .tr td > span, table thead tr .td > span, table thead tr td > span, table tbody .tr .td > span, table tbody .tr td > span, table tbody tr .td > span, table tbody tr td > span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.table div .tr .td > span.bakiye, .table div .tr td > span.bakiye, .table div tr .td > span.bakiye, .table div tr td > span.bakiye, .table thead .tr .td > span.bakiye, .table thead .tr td > span.bakiye, .table thead tr .td > span.bakiye, .table thead tr td > span.bakiye, .table tbody .tr .td > span.bakiye, .table tbody .tr td > span.bakiye, .table tbody tr .td > span.bakiye, .table tbody tr td > span.bakiye, table div .tr .td > span.bakiye, table div .tr td > span.bakiye, table div tr .td > span.bakiye, table div tr td > span.bakiye, table thead .tr .td > span.bakiye, table thead .tr td > span.bakiye, table thead tr .td > span.bakiye, table thead tr td > span.bakiye, table tbody .tr .td > span.bakiye, table tbody .tr td > span.bakiye, table tbody tr .td > span.bakiye, table tbody tr td > span.bakiye {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table div .trmobil .td, .table div .trmobil > td, .table thead .trmobil .td, .table thead .trmobil > td, .table tbody .trmobil .td, .table tbody .trmobil > td, table div .trmobil .td, table div .trmobil > td, table thead .trmobil .td, table thead .trmobil > td, table tbody .trmobil .td, table tbody .trmobil > td {
  width: 100% !important;
  display: flex;
  border-right: none;
  border-left: none;
}
.table div .trmobil:hover, .table thead .trmobil:hover, .table tbody .trmobil:hover, table div .trmobil:hover, table thead .trmobil:hover, table tbody .trmobil:hover {
  background-color: #FFFFFF;
}
.table div .islemler, .table thead .islemler, .table tbody .islemler, table div .islemler, table thead .islemler, table tbody .islemler {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 3px;
}
.table div .islemler button, .table div .islemler a, .table thead .islemler button, .table thead .islemler a, .table tbody .islemler button, .table tbody .islemler a, table div .islemler button, table div .islemler a, table thead .islemler button, table thead .islemler a, table tbody .islemler button, table tbody .islemler a {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  background-color: var(--brand-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
}
.table div .islemler button:hover, .table div .islemler a:hover, .table thead .islemler button:hover, .table thead .islemler a:hover, .table tbody .islemler button:hover, .table tbody .islemler a:hover, table div .islemler button:hover, table div .islemler a:hover, table thead .islemler button:hover, table thead .islemler a:hover, table tbody .islemler button:hover, table tbody .islemler a:hover {
  background-color: var(--brand-orange);
  color: var(--brand-blue);
}
.table div .islemler .detay, .table thead .islemler .detay, .table tbody .islemler .detay, table div .islemler .detay, table thead .islemler .detay, table tbody .islemler .detay {
  background-color: #27ae60;
}
.table div .islemler .sil, .table thead .islemler .sil, .table tbody .islemler .sil, table div .islemler .sil, table thead .islemler .sil, table tbody .islemler .sil {
  background-color: #d63031;
}
.table div .islemler .duzenle, .table thead .islemler .duzenle, .table tbody .islemler .duzenle, table div .islemler .duzenle, table thead .islemler .duzenle, table tbody .islemler .duzenle {
  background-color: #8e44ad;
}
.table div .islemler .konum, .table thead .islemler .konum, .table tbody .islemler .konum, table div .islemler .konum, table thead .islemler .konum, table tbody .islemler .konum {
  background-color: #f39c12;
}
.table div .islemler .konumEkliDegil, .table thead .islemler .konumEkliDegil, .table tbody .islemler .konumEkliDegil, table div .islemler .konumEkliDegil, table thead .islemler .konumEkliDegil, table tbody .islemler .konumEkliDegil {
  background-color: #7f8fa6;
}
.table div .islemler .bakimIptal, .table thead .islemler .bakimIptal, .table tbody .islemler .bakimIptal, table div .islemler .bakimIptal, table thead .islemler .bakimIptal, table tbody .islemler .bakimIptal {
  background-color: #e74c3c;
}
.table div .islemler .bakimPlanla, .table thead .islemler .bakimPlanla, .table tbody .islemler .bakimPlanla, table div .islemler .bakimPlanla, table thead .islemler .bakimPlanla, table tbody .islemler .bakimPlanla {
  background-color: #3498db;
}
.table div .islemler .bakimYap, .table thead .islemler .bakimYap, .table tbody .islemler .bakimYap, table div .islemler .bakimYap, table thead .islemler .bakimYap, table tbody .islemler .bakimYap {
  background-color: #f1c40f;
}
.table div .islemler .planSil, .table thead .islemler .planSil, .table tbody .islemler .planSil, table div .islemler .planSil, table thead .islemler .planSil, table tbody .islemler .planSil {
  background-color: #eb2f06;
}
.table div .islemler .bakimYapilamadi, .table thead .islemler .bakimYapilamadi, .table tbody .islemler .bakimYapilamadi, table div .islemler .bakimYapilamadi, table thead .islemler .bakimYapilamadi, table tbody .islemler .bakimYapilamadi {
  background-color: #b71540;
}
.table div .islemler .geriAl, .table thead .islemler .geriAl, .table tbody .islemler .geriAl, table div .islemler .geriAl, table thead .islemler .geriAl, table tbody .islemler .geriAl {
  background-color: #A3CB38;
}
.table div .islemler .kaldir, .table thead .islemler .kaldir, .table tbody .islemler .kaldir, table div .islemler .kaldir, table thead .islemler .kaldir, table tbody .islemler .kaldir {
  background-color: #c23616;
}

.content {
  background-color: #dcdde1;
  flex: 1;
  overflow: auto;
  padding: 15px 15px 200px;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #F4F4FB;
  flex-wrap: wrap;
}
.pagination .pageControl {
  flex: 1;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination .pageControl section {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.pagination .pageControl section select {
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
}
.pagination .pageControl section input {
  padding: 5px;
  width: 50px;
  text-align: center;
  font-size: 14px;
}
.pagination .pageControl section span {
  color: #9FA2B7;
  white-space: nowrap;
}
.pagination .pageInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
}
.pagination nav {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.pagination nav button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--brand-blue);
  color: white;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}
.pagination nav button:hover {
  background-color: var(--brand-orange);
  color: var(--brand-blue);
}

.table_search {
  padding: 0 15px;
}

.wrapper {
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
}
.wrapper input[name=search] {
  margin-bottom: 10px;
}
.wrapper .zorunlu_yazi {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 5px;
}
.wrapper .zorunlu_yazi span {
  display: flex;
  align-items: center;
  color: #e74c3c;
}
.wrapper .panelTitle {
  margin-top: 20px;
}
.wrapper fieldset {
  border: 1px solid #aaaaaa;
  padding: 20px 10px;
  margin: 10px 0;
}

.container {
  background-color: white;
  overflow: auto;
}

.panelTitle {
  background-color: var(--brand-blue);
  color: white;
  padding: 20px;
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.panelTitle .button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: var(--brand-blue);
  border: 2px solid var(--brand-orange);
  color: white;
  border-radius: 10px;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
}
.panelTitle .button:hover {
  background-color: var(--brand-orange);
  color: var(--brand-blue);
}
.panelTitle svg {
  color: var(--brand-orange);
  font-size: 20px;
}

.products {
  padding: 15px;
  cursor: default;
}
.products .product {
  overflow: hidden;
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.products .product:hover .productImage .productButtons {
  opacity: 1;
}
.products .product .productHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.products .product .productHeader h3 {
  text-align: center;
  font-size: 20px;
  color: var(--brand-orange);
  font-weight: bold;
}
.products .product .productImage {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}
.products .product .productImage img {
  width: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 10px;
}
.products .product .productImage .productButtons {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
}
.products .product .productImage .productButtons a, .products .product .productImage .productButtons button {
  height: 40px;
  padding: 0 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  gap: 10px;
  font-size: 15px;
  transition: transform 0.3s;
}
.products .product .productImage .productButtons a:nth-child(n+1), .products .product .productImage .productButtons button:nth-child(n+1) {
  background-color: var(--brand-orange);
}
.products .product .productImage .productButtons a:nth-child(n+1) svg, .products .product .productImage .productButtons button:nth-child(n+1) svg {
  color: var(--brand-blue);
  font-size: 25px;
}
.products .product .productImage .productButtons a:nth-child(n+2), .products .product .productImage .productButtons button:nth-child(n+2) {
  background-color: var(--brand-blue);
}
.products .product .productImage .productButtons a:nth-child(n+2) svg, .products .product .productImage .productButtons button:nth-child(n+2) svg {
  color: var(--brand-orange);
  font-size: 25px;
}
.products .product .productImage .productButtons a:hover, .products .product .productImage .productButtons button:hover {
  transform: scale(1.05);
}
.products .product .productPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}
.products .product .productPrice .productPriceOld {
  color: #44bd32;
  font-weight: bold;
}
.products .product .productPrice .productPriceOld span {
  text-decoration: line-through;
  color: #444444;
  font-weight: normal;
}
.products .product .productPrice .productPriceNew {
  font-weight: bold;
  font-size: 25px;
  color: var(--brand-orange);
}
.products .moreProducts {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.products .moreProducts a, .products .moreProducts button {
  height: 40px;
  padding: 0 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-blue);
  color: #FFFFFF;
  cursor: pointer;
  gap: 10px;
  transition: transform 0.3s;
}
.products .moreProducts a:hover, .products .moreProducts button:hover {
  transform: scale(1.2);
}
.products .moreProducts a svg, .products .moreProducts button svg {
  color: var(--brand-orange);
  font-size: 25px;
}

.profile {
  padding: 25px 0;
}
.profile h1 {
  font-size: 30px;
  padding: 10px;
  color: #222222;
}
.profile .addresses {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
}
.profile .addresses h2 {
  font-size: 25px;
  color: #444444;
  text-align: center;
  margin-bottom: 20px;
}
.profile .addresses .noAddress h3 {
  text-align: center;
  padding: 20px;
  color: gray;
}
.profile .addresses > button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  padding: 0 15px;
  border-radius: 20px;
  cursor: pointer;
}
.profile .addresses section {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}
.profile .addresses section .address {
  border-radius: 10px;
  border: 1px solid #dddddd;
  width: calc(100% / 4 - 15px);
  overflow: hidden;
}
.profile .addresses section .address h3 {
  font-size: 25px;
  color: #222222;
  padding: 20px 10px 10px;
}
.profile .addresses section .address p {
  color: #444444;
  padding: 0 15px 25px 15px;
}
.profile .addresses section .address .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.profile .addresses section .address .buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-size: 16px;
  gap: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
}
.profile .addresses section .address .buttons button svg {
  font-size: 23px;
}
.profile .addresses section .address .buttons button:hover {
  background-color: var(--brand-orange);
}

.cart {
  padding: 20px 0;
}
.cart .noProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.cart .noProducts p {
  font-size: 18px;
  color: gray;
  text-align: center;
}
.cart .noProducts a {
  background-color: var(--brand-orange);
  color: #FFF;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.cart .noProducts a:hover {
  background-color: var(--brand-blue);
}
.cart h1 {
  font-size: 30px;
  padding: 20px 5px;
}
.cart .total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #ddd;
  gap: 7px;
  overflow: hidden;
}
.cart .total h2 {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  background-color: var(--brand-orange);
  color: #FFFFFF;
  width: 100%;
}
.cart .total section {
  width: 100%;
  padding: 20px;
}
.cart .total section span {
  font-size: 35px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart .total section button {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: var(--brand-blue);
  font-size: 25px;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s;
}
.cart .total section button:hover {
  background-color: var(--brand-orange);
}
.cart .product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}
.cart .product .productImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.cart .product .productImage img {
  width: 100px;
}
.cart .product .productInfo {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cart .product .productInfo h3 a {
  color: #444444;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
  display: block;
  width: 100%;
}
.cart .product .productInfo h3 a:hover {
  color: #222222;
}
.cart .product .productInfo .productPrice {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.cart .product .productInfo .productPrice .productPriceOld {
  color: #44bd32;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
}
.cart .product .productInfo .productPrice .productPriceOld span {
  text-decoration: line-through;
  color: #444444;
  font-weight: normal;
}
.cart .product .productInfo .productPrice .productPriceNew {
  font-weight: bold;
  font-size: 30px;
  color: var(--brand-orange);
  white-space: nowrap;
}
.cart .product .productControl {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}
.cart .product .productControl .productButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.cart .product .productControl .productButtons > button {
  height: 50px;
  padding: 0 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  gap: 10px;
  font-size: 15px;
  transition: background-color 0.3s;
  background-color: var(--brand-orange);
}
.cart .product .productControl .productButtons > button svg {
  color: var(--brand-blue);
  transition: color 0.3s;
  font-size: 25px;
}
.cart .product .productControl .productButtons > button:hover {
  background-color: var(--brand-blue);
}
.cart .product .productControl .productButtons > button:hover svg {
  color: var(--brand-orange);
}
.cart .product .productControl .productButtons .productAmountController {
  display: flex;
  /* Firefox */
}
.cart .product .productControl .productButtons .productAmountController input::-webkit-outer-spin-button,
.cart .product .productControl .productButtons .productAmountController input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart .product .productControl .productButtons .productAmountController input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
  height: 30px;
  padding: 0 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  position: relative;
  z-index: 1;
}
.cart .product .productControl .productButtons .productAmountController .controller {
  display: flex;
  flex-direction: column;
}
.cart .product .productControl .productButtons .productAmountController .controller button {
  height: 15px;
  padding: 0 10px 0 30px;
  transform: translateX(-20px);
  border-bottom: 1px solid #FFFFFF;
  background-color: var(--brand-orange);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: background-color 0.3s, color 0.3s;
}
.cart .product .productControl .productButtons .productAmountController .controller button:first-child {
  border-top-right-radius: 5px;
}
.cart .product .productControl .productButtons .productAmountController .controller button:last-child {
  border-bottom-right-radius: 5px;
}
.cart .product .productControl .productButtons .productAmountController .controller button:hover {
  background-color: var(--brand-blue);
  color: var(--brand-orange);
  cursor: pointer;
}
.cart .product .productControl .productButtons .deleteProduct button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: gray;
  background-color: transparent;
  padding: 5px;
  transition: color 0.3s;
  cursor: pointer;
}
.cart .product .productControl .productButtons .deleteProduct button:hover {
  color: var(--brand-orange);
}

.cartCheck h1 {
  text-align: center;
  font-size: 25px;
  padding: 10px;
  color: #444444;
}
.cartCheck .addresses {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
}
.cartCheck .addresses h2 {
  font-size: 25px;
  color: #444444;
  text-align: center;
  margin-bottom: 20px;
}
.cartCheck .addresses .noAddress h3 {
  text-align: center;
  padding: 20px;
  color: gray;
}
.cartCheck .addresses > button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  padding: 0 15px;
  border-radius: 20px;
  cursor: pointer;
}
.cartCheck .addresses section {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}
.cartCheck .addresses section label {
  width: calc(100% / 4 - 15px);
  cursor: pointer;
}
.cartCheck .addresses section label input {
  display: none;
}
.cartCheck .addresses section label input:checked + .address {
  background-color: #444444;
}
.cartCheck .addresses section label input:checked + .address svg {
  visibility: visible;
  opacity: 1;
}
.cartCheck .addresses section label input:checked + .address h3 {
  color: #FFFFFF;
}
.cartCheck .addresses section label input:checked + .address p {
  color: #DDDDDD;
}
.cartCheck .addresses section label .address {
  transition: background-color 0.3s;
  position: relative;
  border-radius: 10px;
  border: 1px solid #dddddd;
}
.cartCheck .addresses section label .address svg {
  visibility: visible;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  position: absolute;
  color: var(--brand-backgorund);
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #FFFFFF;
  transform: translate(10px, -10px);
}
.cartCheck .addresses section label .address h3 {
  font-size: 25px;
  color: #222222;
  padding: 20px 10px 10px;
  transition: color 0.3s;
}
.cartCheck .addresses section label .address p {
  color: #444444;
  padding: 0 15px 25px 15px;
  transition: color 0.3s;
}
.cartCheck .addresses section label .address .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.cartCheck .addresses section label .address .buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-size: 16px;
  gap: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
}
.cartCheck .addresses section label .address .buttons button svg {
  font-size: 23px;
}
.cartCheck .addresses section label .address .buttons button:hover {
  background-color: var(--brand-orange);
}

.productContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 190px);
}
.productContainer .product {
  display: flex;
  width: 100%;
  max-width: 1200px;
}
.productContainer .product .productImage {
  flex: 1;
}
.productContainer .product .productImage img {
  width: 100%;
  border-radius: 10px;
}
.productContainer .product .productInfo {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.productContainer .product .productInfo h2 {
  color: #484848;
  font-size: 40px;
  font-weight: 600;
}
.productContainer .product .productInfo .productPrice {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.productContainer .product .productInfo .productPrice .productPriceOld {
  color: #44bd32;
  font-weight: bold;
  font-size: 20px;
}
.productContainer .product .productInfo .productPrice .productPriceOld span {
  text-decoration: line-through;
  color: #444444;
  font-weight: normal;
}
.productContainer .product .productInfo .productPrice .productPriceNew {
  font-weight: bold;
  font-size: 50px;
  color: var(--brand-orange);
}
.productContainer .product .productInfo .productButtons {
  display: flex;
}
.productContainer .product .productInfo .productButtons > button {
  height: 50px;
  padding: 0 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  gap: 10px;
  font-size: 15px;
  transition: background-color 0.3s;
  background-color: var(--brand-orange);
}
.productContainer .product .productInfo .productButtons > button svg {
  color: var(--brand-blue);
  transition: color 0.3s;
  font-size: 25px;
}
.productContainer .product .productInfo .productButtons > button:hover {
  background-color: var(--brand-blue);
}
.productContainer .product .productInfo .productButtons > button:hover svg {
  color: var(--brand-orange);
}
.productContainer .product .productInfo .productButtons section {
  display: flex;
  /* Firefox */
}
.productContainer .product .productInfo .productButtons section input::-webkit-outer-spin-button,
.productContainer .product .productInfo .productButtons section input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.productContainer .product .productInfo .productButtons section input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
  height: 50px;
  padding: 0 25px;
  border: 1px solid #ddd;
  border-radius: 25px;
  position: relative;
  z-index: 1;
}
.productContainer .product .productInfo .productButtons section .controller {
  display: flex;
  flex-direction: column;
}
.productContainer .product .productInfo .productButtons section .controller button {
  height: 25px;
  padding: 0 10px 0 30px;
  transform: translateX(-20px);
  border-bottom: 1px solid #FFFFFF;
  background-color: var(--brand-orange);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}
.productContainer .product .productInfo .productButtons section .controller button:first-child {
  border-top-right-radius: 5px;
}
.productContainer .product .productInfo .productButtons section .controller button:last-child {
  border-bottom-right-radius: 5px;
}
.productContainer .product .productInfo .productButtons section .controller button:hover {
  background-color: var(--brand-blue);
  color: var(--brand-orange);
  cursor: pointer;
}

.addDirectory {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
}
.addDirectory h2 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 25px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.addDirectory a {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  padding: 0 35px;
  gap: 10px;
  font-size: 20px;
  cursor: pointer;
}
.addDirectory a svg {
  font-size: 35px;
}

.swiper {
  height: calc(100vh - 190px);
}
.swiper .swiper-wrapper .swiper-slide {
  position: relative;
}
.swiper .swiper-wrapper .swiper-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.swiper .swiper-wrapper .swiper-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editArea {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 50px;
}
.editArea svg {
  transition: transform 0.3s;
}
.editArea:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.editArea:hover svg {
  transform: scale(1.2);
}

.security {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.security h2 {
  font-size: 20px;
  font-weight: bold;
}
.security section {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.security section button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
  color: var(--brand-color);
  border: none;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.security section button:hover {
  background-color: #0b72de;
}
.security section button.active {
  background-color: #ba1616;
}

.wifiQrCode {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
}
.wifiQrCode .code {
  padding: 4px 4px 0 4px;
  background-color: white;
}

label .input_title {
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  gap: 3px;
}
label .input_title span {
  display: flex;
  align-items: center;
  font-size: 8px;
  color: #d31d0b;
}
label .input_area {
  position: relative;
  font-size: 12px;
}
label .input_area .input {
  width: 100%;
  padding: 0 5px 0 40px;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #888;
  -webkit-appearance: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  resize: vertical;
}
label .input_area textarea.input {
  padding: 10px 10px 10px 40px;
}
label .input_area > div > div {
  cursor: pointer;
}
label .input_area > div > div > div {
  padding: 0;
}
label .input_area > div > div > div svg {
  cursor: pointer;
}
label .input_area > div > div:nth-child(3) {
  padding-left: 40px;
}
label .input_area i {
  position: absolute;
  bottom: 0;
  left: 0;
  border-right: 1px solid #ddd;
  transition: color 0.3s;
  height: 100%;
  width: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 20px;
}

label .input_area input:focus + i, label .input_area select:focus + i label, .input_area textarea:focus + i, .input_area select:focus + i {
  color: #FC5E28;
}

label.check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
label.check input {
  display: none;
}
label.check input:checked ~ div {
  border: 1px solid var(--brand-orange);
}
label.check input:checked ~ div span {
  background-color: var(--brand-orange);
}
label.check div {
  height: 25px;
  width: 25px;
  position: relative;
  border-radius: 5px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s border-color;
  cursor: pointer;
}
label.check div:hover {
  border: 1px solid var(--brand-orange);
}
label.check div span {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 5px;
  transition: 0.3s background-color;
  background-color: #bbbbbb;
}

::-webkit-file-upload-button {
  height: 100%;
  margin: 0 5px 0 -5px;
  border: none;
  background-color: var(--brand-backgorund);
  color: var(--brand-color);
  padding: 0 10px;
  cursor: pointer;
  transition: 0.3s color;
}
::-webkit-file-upload-button:hover {
  color: var(--brand-color);
}

.goCart {
  color: var(--brand-orange);
  margin: 5px;
  cursor: pointer;
  transition: color 0.3s;
  background-color: transparent;
}
.goCart:hover {
  color: var(--brand-blue);
}

.card {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  min-height: 500px;
  background-color: white;
  padding-bottom: 100px;
}
.card .cardHeader {
  position: relative;
}
.card .cardHeader .actionBar {
  position: absolute;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 3;
}
.card .cardHeader .actionBar section {
  display: flex;
  gap: 20px;
}
.card .cardHeader .actionBar section button, .card .cardHeader .actionBar section a {
  background: transparent;
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 40px;
  z-index: 1;
}
.card .cardHeader .actionBar section button:hover, .card .cardHeader .actionBar section a:hover {
  color: lightgray;
}
.card .cardHeader > section {
  height: 350px;
  width: 100%;
  position: relative;
  background-size: cover;
}
.card .cardHeader > section .businessLogoWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.card .cardHeader > section .businessLogoWrapper .businessLogo {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50px;
  transform: translateY(-50%);
  background: url("https://chint.com.tr/wp-content/uploads/2022/03/urun-noimage.jpg");
  background-size: cover;
}
.card .cardHeader > section .businessLogoWrapper .businessLogo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card .cardHeader .profile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-100px);
  position: relative;
  z-index: 3;
}
.card .cardHeader .profile .photo {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  background: url("https://chint.com.tr/wp-content/uploads/2022/03/urun-noimage.jpg");
  background-size: cover;
}
.card .cardHeader .profile .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .cardHeader .profile section {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card .cardHeader .profile section .details {
  width: 100%;
  padding: 10px;
}
.card .cardHeader .profile section .details ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card .cardHeader .profile section .details ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: inherit;
}
.card .cardHeader .profile section .details ul li svg {
  font-size: 30px;
  opacity: 0.5;
}
.card .cardHeader .profile section .details ul li span {
  transition: transform 0.3s;
  color: inherit;
  cursor: default;
  font-size: 14px;
}
.card .cardHeader .profile section .details ul li span a {
  color: inherit;
  cursor: pointer;
}
.card .cardHeader .profile section .details ul li span:hover {
  transform: scale(1.01);
}
.card .cardHeader .profile section h2 {
  font-size: 30px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.card .cardHeader .profile section h2 button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: inherit;
  background-color: transparent;
}
.card .cardHeader .profile section i {
  font-size: 16px;
}
.card .cardHeader .profile section b {
  font-size: 14px;
}
.card .cardBody .infoBox {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 350px;
  gap: 30px;
}
.card .cardBody .infoBox h2 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 25px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.card .cardBody .infoBox h2 button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: inherit;
  background-color: transparent;
}
.card .cardBody .infoBox section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 50px;
}
.card .cardBody .infoBox section .button {
  width: calc(100% / 4);
}
.card .cardBody .infoBox section .button button, .card .cardBody .infoBox section .button a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  border: none;
  font-size: 35px;
  margin: 0 auto;
  cursor: pointer;
}
.card .cardBody .infoBox .addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}
.card .cardBody .infoBox .addButton button, .card .cardBody .infoBox .addButton a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: transparent;
  font-size: 35px;
  margin: 0 auto;
  cursor: pointer;
  border: 2px solid white;
  transition: transform 0.3s;
}
.card .cardBody .infoBox .addButton button:hover, .card .cardBody .infoBox .addButton a:hover {
  transform: scale(1.1);
}
.card .cardBody .imageBox {
  position: relative;
}
.card .cardBody .imageBox .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .cardBody .imageBox .header h2 {
  text-align: center;
  font-size: 24px;
  padding: 20px 0;
}
.card .cardBody .imageBox .body {
  padding: 0 20px;
}
.card .cardBody .imageBox .body img {
  width: 100%;
}
.card .cardBody .imageBox .body p {
  text-align: center;
  font-size: 20px;
  padding: 20px 5px;
}
.card .cardBody .imageBox .footer {
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}
.card .cardBody .videoBox {
  position: relative;
}
.card .cardBody .videoBox .header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .cardBody .videoBox .body {
  padding: 20px 20px 0 20px;
}
.card .cardBody .videoBox .body .video {
  height: 0;
  padding-top: calc(100% * 9 / 16);
  position: relative;
}
.card .cardBody .videoBox .body .video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
}
.card .cardBody .videoBox .body h2 {
  font-size: 24px;
  padding: 10px;
}
.card .cardBody .videoBox .body p {
  font-size: 20px;
  padding: 10px;
}
.card .cardBody .videoBox .footer {
  padding: 20px 30px;
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
}
.card .cardBody .spotifyBox {
  position: relative;
}
.card .cardBody .spotifyBox .playlist {
  height: 0;
  padding-top: 80%;
  position: relative;
}
.card .cardBody .spotifyBox .playlist iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
}
.card .cardBody .addComponent {
  padding: 20px;
}
.card .cardBody .addComponent button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 2px dashed gray;
  color: gray;
  font-size: 100px;
  background-color: transparent;
  outline: none;
  transition: transform 0.3s;
  cursor: pointer;
}
.card .cardBody .addComponent button:hover {
  transform: scale(1.05);
}
.card .languageSelect {
  padding: 5px;
  color: #000000;
  position: relative;
  z-index: 10;
}

.button_area {
  gap: 10px;
  flex-wrap: wrap-reverse;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
}
.button_area .button {
  flex: 1;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.button_area .button .loading {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-backgorund);
}
.button_area .button .loading img {
  height: 90%;
}
.button_area .button button, .button_area .button a {
  color: var(--brand-color);
  padding: 10px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s background-color, 0.3s color;
  border: none;
  outline: none !important;
  background-color: var(--brand-backgorund);
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}
.button_area .button button svg, .button_area .button a svg {
  color: var(--brand-color);
  font-size: 20px;
  transition: color 0.3s;
  flex-shrink: 0;
}
.button_area .button button:hover, .button_area .button a:hover {
  background-color: var(--brand-color);
  color: var(--brand-backgorund);
}
.button_area .button button:hover svg, .button_area .button a:hover svg {
  color: var(--brand-backgorund);
}

.MenuWrapper {
  position: fixed;
  inset: 0;
  z-index: 10;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MenuWrapper .MenuCloseBack {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.MenuWrapper .Menu {
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 15px;
  min-height: 40vh;
  max-height: 80vh;
  border-radius: 10px;
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.MenuWrapper .Menu::-webkit-scrollbar {
  display: none;
}
.MenuWrapper .Menu form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.MenuWrapper .Menu h2 {
  padding: 10px;
  color: #444444;
}

footer {
  border-top: 5px solid var(--brand-backgorund);
  padding: 20px 0 50px 0;
}
footer h2 {
  font-size: 24px;
  padding: 10px 0;
  color: #444444;
  font-weight: bold;
}
footer p {
  font-size: 18px;
  color: #444444;
}
footer section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
footer section a, footer section button {
  font-size: 18px;
  color: #444444;
}
footer .socialMedia {
  display: flex;
  gap: 15px;
}
footer .socialMedia a {
  font-size: 20px;
  height: 40px;
  width: 40px;
  background-color: var(--brand-backgorund);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 20px;
  transition: transform 0.3s;
}
footer .socialMedia a:hover {
  transform: scale(1.07);
}

@media only screen and (max-width: 1250px) {
  header.mainHeader {
    padding: 0 20px;
  }
  header.mainHeader > ul {
    height: 120px;
  }
  header.mainHeader > ul:nth-child(1) li a img {
    width: 100px;
    height: 100px;
  }
  header.mainHeader > ul li a, header.mainHeader > ul li button {
    font-size: 16px;
  }
  header.mainHeader > ul li a svg, header.mainHeader > ul li button svg {
    font-size: 22px;
  }
  header.mainHeader > ul li a span svg, header.mainHeader > ul li button span svg {
    font-size: 16px;
  }

  .swiper {
    height: calc(100vh - 160px);
  }
}
@media only screen and (max-width: 1100px) {
  footer {
    padding-bottom: 100px;
  }
}

