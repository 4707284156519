@import url("reset.css");

* {
	margin: 0;
	padding: 0;
	outline: 0;
	text-decoration: none;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
}

:root {
	--brand-backgorund: #2980b9;
	--brand-color: #ecf0f1;
	--aside-button-hover: #060f2c;
	--header-background: #ffffff;
	--aside-background: #040E26;
	--brand-blue: #040E26;
	--brand-orange: #FC5E28;
}

hr {
	border: 0;
	border-top: 1px solid #ccc;
	margin: 20px 0 10px 0;
	width: 100%;
}

body {
	font-family: Calibri, sans-serif;
	overflow-x: hidden;
}

main {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: white;
	overflow-x: auto;
}

header {
	&.mainHeader {
		background: #ffffff;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 50px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		gap: 10px;
		
		> div {
			flex: 1 !important;
			height: 40px;
			display: flex;
			align-items: center;
			
			label {
				flex: 1;
			}
		}
		
		a, button {
			color: #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			background: transparent;
			outline: none;
			cursor: pointer;
			font-size: 20px;
			transition: color .3s;
			white-space: nowrap;
			
			&.profileButton {
				
				border-radius: 20px;
				overflow: hidden;
				background-color: var(--brand-backgorund);
				color: #FFFFFF;
				padding: 0 20px;
				transition: background-color .3s;
				
				&:hover {
					background-color: var(--brand-orange);
					color: #FFFFFF;
				}
			}
			
			> svg {
				font-size: 30px;
			}
			
			&:hover {
				color: #2980b9;
			}
		}
	}
	
	&.panelHeader {
		background-color: var(--header-background);
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		overflow-x: auto;
		
		> ul {
			height: 4rem;
			display: flex;
			gap: 5px;
			align-items: center;
			
			> li {
				> a, > button {
					cursor: pointer;
					background: transparent;
					font-size: 22px;
					display: flex;
					height: 4rem;
					align-items: center;
					justify-content: center;
					padding: 0 10px;
					gap: 5px;
					color: var(--brand-blue);
					transition: color .3s;
					
					&:hover {
						color: var(--brand-orange);
					}
					
					span {
						font-size: 14px;
						white-space: nowrap;
					}
					
					img {
						height: 40px;
						width: 40px;
						object-fit: cover;
						border-radius: 20px;
					}
				}
				
				.dropdown-menu {
					position: absolute;
					padding-top: 10px;
					top: 56px;
					right: 0;
					min-width: 200px;
					overflow: hidden;
					border-radius: 5px;
					transition: .3s;
					z-index: 99;
					visibility: hidden;
					opacity: 0;
					
					&.dropdown-menu-visible {
						opacity: 1;
						visibility: visible;
					}
					
					li {
						a, button {
							display: block;
							white-space: nowrap;
							padding: 10px;
							color: #fff;
							z-index: 99;
							text-decoration: none;
							transition: .3s;
							background-color: #040E26;
							text-align: center;
							text-transform: initial;
							cursor: pointer;
							
							&:hover {
								background-color: #FC5E28;
								color: white;
							}
						}
						
						button {
							border-radius: 0;
							width: 100%;
						}
						
						&:first-child {
							
							a {
								border-radius: 5px 5px 0 0;
							}
						}
					}
				}
			}
		}
	}
}

.loadingScreen {
	position: fixed;
	inset: 0;
	z-index: 2000;
	background-color: var(--brand-backgorund);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: .3s;
	
	&.active {
		transition: 0s;
		visibility: visible;
		opacity: 1;
	}
	
	img {
		&:first-child {
			height: 15vh;
		}
		
		&:last-child {
			height: 5vh;
		}
	}
}

aside {
	background-color: var(--aside-background);
	width: 15rem;
	transition: .3s;
	
	.logo {
		height: 64px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #1A2849;
		
		a {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 20px;
			flex: 1;
			
			img {
				width: 100%;
				max-height: 70%;
				object-fit: contain;
			}
		}
		
		button {
			flex-shrink: 0;
			width: 64px;
			height: 64px;
			background: transparent;
			color: var(--brand-orange);
			font-size: 25px;
			transition: color .3s;
			
			&:hover {
				color: white;
			}
		}
	}
	
	nav {
		height: calc(100vh - 4rem);
		overflow-y: auto;
		
		> ul {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
			
			> li.active {
				> a, section {
					> svg {
						transform: rotate(90deg);
					}
				}
			}
			
			> li {
				> a, section {
					display: flex;
					align-items: center;
					color: white;
					gap: 20px;
					padding: 10px;
					width: 100%;
					cursor: pointer;
					transition: background-color .3s;
					white-space: nowrap;
					
					&:hover {
						background-color: var(--aside-button-hover);
					}
					
					em {
						color: var(--brand-orange);
						font-size: 1.5rem;
					}
					
					span {
						flex: 1;
						font-size: 0.9rem;
						display: flex;
					}
					
					> svg {
						font-size: 0.8rem;
						transition: transform .3s;
					}
				}
				
				ul {
					background-color: #0c1a41;
					overflow: hidden;
					display: none;
					flex-direction: column;
					row-gap: 5px;
					
					li {
						
						a {
							display: flex;
							align-items: center;
							font-size: 0.7rem;
							color: white;
							gap: 20px;
							padding: 10px;
							transition: background-color .3s;
							
							&:hover {
								background-color: var(--aside-button-hover);
							}
							
							svg {
								color: var(--brand-orange);
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}

.myCards {
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	
	.myCard {
		display: flex;
		justify-content: space-between;
		flex: 1;
		position: relative;
		border: 1px solid #ddd;
		overflow: hidden;
		transition: .3s all;
		min-width: 250px;
		min-height: 150px;
		
		&:hover {
			flex: 2;
			
			.cardInfo {
				opacity: 1;
			}
		}
		
		section {
			position: absolute;
			inset: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 10px;
			gap: 5px;
			
			h4 {
				font-size: 30px;
				text-align: center;
			}
			
			span {
				font-size: 16px;
				opacity: .8;
			}
		}
		
	}
}

.HomeContent {
	flex: 1;
}

.HomeContainer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.PanelContainer {
	display: flex;
	height: 100vh;
	overflow: hidden;
}

.form {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	flex: 1;
	min-height: 100vh;
	
	form {
		width: 100%;
		max-width: 500px;
		box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
		border: 1px solid #ddd;
		background-color: white;
		border-radius: 10px;
		padding: 20px;
		
		h2 {
			text-align: center;
			font-size: 50px;
			margin-top: 70px;
			margin-bottom: 70px;
		}
		
		section {
			display: flex;
			justify-content: flex-end;
			
			span {
				color: #444444;
				padding: 5px;
				
				a {
					color: var(--brand-backgorund);
					white-space: nowrap;
				}
			}
		}
	}
}

.hakkimizda {
	margin: 50px 0;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	
	h2 {
		font-size: 30px;
		font-weight: bold;
		color: #2980b9;
		text-align: center;
	}
	
	p {
		text-align: center;
		font-size: 20px;
		margin-top: 10px;
	}
}

.questions {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 0;
	
	h3 {
		font-size: 30px;
		font-weight: bold;
		color: #2980b9;
		text-align: center;
	}
	
	.question {
		border: 1px solid #ddd;
		border-radius: 15px;
		cursor: pointer;
		
		
		&.active {
			height: auto;
		}
		
		h2 {
			height: 60px;
			display: flex;
			align-items: center;
			padding: 0 20px;
			font-weight: normal;
			transition: background-color .3s;
			
			&:hover {
				background: #f5f5f5;
			}
		}
		
		section {
			padding: 0 20px;
			display: none;
			overflow: hidden;
			
			p {
				padding: 20px;
			}
		}
		
		ul {
			list-style: decimal;
			padding-left: 25px;
			display: flex;
			flex-direction: column;
			gap: 5px;
			
			li {
				font-size: 20px;
				
			}
		}
	}
}

.devices {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 0;
	
	h3 {
		font-size: 30px;
		font-weight: bold;
		color: #2980b9;
		text-align: center;
	}
	
	.device {
		border: 1px solid #ddd;
		border-radius: 15px;
		cursor: pointer;
		overflow: hidden;
		
		h2 {
			height: 60px;
			display: flex;
			align-items: center;
			padding: 0 20px;
			font-weight: normal;
			gap: 15px;
			transition: background-color .3s;
			
			svg {
				color: #2980b9;
			}
			
			&:hover {
				background: #f5f5f5;
			}
		}
		
		section {
			padding: 0 20px;
			display: none;
			overflow: hidden;
			
			ul {
				padding: 20px 0 20px 25px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				
				li {
					font-size: 20px;
					
					&:before {
						content: "\2022";
						color: var(--brand-backgorund);
						font-weight: bold;
						display: inline-block;
						width: 1em;
						margin-left: -1em;
					}
				}
			}
		}
	}
}

.widgets {
	padding: 15px;
	
	> div {
		> div {
			padding: 0 !important;
		}
	}
	
	.widget {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;
		padding: 30px 20px;
		cursor: default;
		transition: box-shadow .3s;
		
		.widgetHeader {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			
			svg {
				width: 50px;
				height: 50px;
				color: #0160E7;
			}
			
			h3 {
				font-size: 20px;
				font-weight: bold;
				color: #000000;
			}
		}
		
		.widgetContent {
			p {
				text-align: center;
			}
		}
		
		&:hover {
			box-shadow: 8px 8px 30px 0 rgb(42 67 113 / 15%);
		}
	}
}

.topBar {
	height: 40px;
	background-color: var(--brand-backgorund);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	
	section {
		display: flex;
		align-items: center;
		gap: 10px;
		
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
			font-size: 16px;
		}
	}
}

.cardEditBar {
	display: flex;
	align-items: center;
	justify-content: center;
	
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background-color: var(--brand-backgorund);
		color: var(--brand-color);
		height: 40px;
		padding: 0 25px;
		cursor: pointer;
		flex: 1;
	}
}

.userCards {
	.addCard {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px;
		
		button, a {
			color: var(--brand-color);
			padding: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			transition: .3s background-color, .3s color;
			border-radius: 5px;
			outline: none !important;
			background-color: var(--brand-backgorund);
			border: 1px solid var(--brand-backgorund);
			white-space: nowrap;
			cursor: pointer;
			font-weight: 600;
			
			svg {
				color: var(--brand-color);
				font-size: 20px;
				transition: color .3s;
				flex-shrink: 0;
			}
			
			&:hover {
				background-color: var(--brand-color);
				color: var(--brand-backgorund);
				
				svg {
					color: var(--brand-backgorund);
				}
			}
		}
	}
	
	.userCard {
		position: relative;
		padding-top: calc(100% * 9 / 16);
		display: block;
		margin-bottom: 20px;
		section {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			
			.info {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				gap: 5px;
				border: 1px solid #ddd;
				border-radius: 5px 5px 0 0;
				h2 {
					font-size: 25px;
				}
				
				i {
					opacity: .8;
					font-size: 17px;
				}
			}
			
			.buttons {
				display: flex;
				width: 100%;
				overflow: hidden;
				border-radius: 0 0 5px 5px;
				border: 1px solid var(--brand-backgorund);
				
				button, a {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 5px;
					background-color: var(--brand-backgorund);
					color: #FFFFFF;
					transition: background-color .3s, color .3s;
					cursor: pointer;
					&:hover {
						background-color: var(--brand-color);
						color: var(--brand-backgorund);
					}
				}
			}
		}
	}
}

.move {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	
	.loading {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(black, 0.5);
		
		img {
			height: 90%;
		}
	}
	
	button {
		display: flex;
		align-items: center;
		background: transparent;
		justify-content: center;
		border: none;
		padding: 10px;
		font-size: 25px;
		cursor: pointer;
		transition: transform .3s;
		
		&:hover {
			transform: scale(1.3);
		}
	}
}

.cardWrapper {
	min-height: 100vh;
}

.input:not(textarea) {
	height: 40px;
}

.qrMenuWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	inset: 0;
	z-index: 9;
	
	.qrMenuCloseBack {
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		inset: 0;
	}
	
	.qrMenu {
		width: 100%;
		max-width: 500px;
		height: 100%;
		max-height: 70vh;
		background-color: #fff;
		padding: 20px;
		border-radius: 5px;
		position: relative;
		z-index: 11;
		
		.qrCode {
			width: 100%;
			height: 100%;
			background-color: #fff;
			border: 1px solid #000;
			border-radius: 5px;
			display: flex;
			gap: 20px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			.qrCodeText {
				font-size: 20px;
				font-weight: bold;
			}
		}
	}
}

.MobileMenu {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	background-color: var(--brand-backgorund);
	z-index: 99;
	
	> ul {
		display: flex;
		justify-content: space-between;
		width: 100%;
		
		> li {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			
			> a, > button {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				background: transparent;
				text-align: center;
				padding: 10px 0;
				gap: 5px;
				cursor: pointer;
				
				svg {
					font-size: 25px;
				}
				
				span {
					font-size: 10px;
				}
			}
			
			ul {
				position: fixed;
				bottom: 60px;
				width: 100%;
				left: 0;
				opacity: 0;
				visibility: hidden;
				transition: .3s;
				
				&.dropdown-menu-visible {
					opacity: 1;
					visibility: visible;
				}
				
				li {
					a, button {
						background-color: var(--brand-backgorund);
						color: #FFFFFF;
						display: block;
						padding: 10px;
						cursor: pointer;
						width: 100%;
						text-align: center;
						transition: color .3s, background-color .3s;
						
						&:hover {
							background-color: var(--brand-orange);
							color: var(--brand-blue);
						}
					}
				}
			}
			
		}
	}
}

.table, table {
	width: 100%;
	border-collapse: collapse;
	background-color: white;
	overflow-x: auto;
	font-size: 16px;
	display: table;
	box-sizing: border-box;
	text-indent: initial;
	border-spacing: 2px;
	border-color: grey;
	
	div, thead, tbody {
		
		.tr, tr {
			display: flex;
			background-color: #FFFFFF;
			transition: background-color .3s, color .3s;
			
			&.rowColor {
				background-color: #f1f2f6;
			}
			
			&:hover {
				background-color: #ced6e0;
			}
			
			.th, th {
				color: #FFFFFF;
				background-color: var(--brand-blue);
				position: relative;
				flex: 1;
				text-align: center;
				min-height: 40px;
				
				span {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
				}
				
			}
			
			.td, td {
				text-align: center;
				border-bottom: 1px solid #ddd;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				flex: 1;
				min-height: 40px;
				font-size: 14px;
				border-right: 1px solid #ddd;
				
				section {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 40px;
					text-align: center;
					
					> span {
						display: flex;
						align-items: center;
						justify-content: center;
						min-height: 40px;
						width: 100%;
					}
					
					&:first-child {
						width: 100px;
						border-right: 1px solid #ddd;
					}
					
					&:last-child {
						flex: 1;
					}
				}
				
				> i {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					
					&:focus {
						background-color: transparent;
					}
				}
				
				> span {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					
					&.bakiye {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
		
		.trmobil {
			.td, > td {
				width: 100% !important;
				display: flex;
				border-right: none;
				border-left: none;
			}
			
			&:hover {
				background-color: #FFFFFF;
			}
		}
		
		.islemler {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			padding: 3px;
			
			button, a {
				cursor: pointer;
				height: 30px;
				width: 30px;
				border-radius: 3px;
				background-color: var(--brand-blue);
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: background-color .3s, color .3s;
				
				&:hover {
					background-color: var(--brand-orange);
					color: var(--brand-blue);
				}
			}
			
			
			.detay {
				background-color: #27ae60;
			}
			
			.sil {
				background-color: #d63031;
			}
			
			.duzenle {
				background-color: #8e44ad;
			}
			
			.konum {
				background-color: #f39c12;
			}
			
			.konumEkliDegil {
				background-color: #7f8fa6;
			}
			
			.bakimIptal {
				background-color: #e74c3c;
			}
			
			.bakimPlanla {
				background-color: #3498db;
			}
			
			.bakimYap {
				background-color: #f1c40f;
			}
			
			.planSil {
				background-color: #eb2f06;
			}
			
			.bakimYapilamadi {
				background-color: #b71540;
			}
			
			.geriAl {
				background-color: #A3CB38;
			}
			
			.kaldir {
				background-color: #c23616;
			}
		}
	}
}

.content {
	background-color: #dcdde1;
	flex: 1;
	overflow: auto;
	padding: 15px 15px 200px;
}

.pagination {
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: #F4F4FB;
	flex-wrap: wrap;
	
	.pageControl {
		flex: 1;
		display: flex;
		gap: 15px;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		
		section {
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex: 1;
			
			select {
				border: 1px solid #ddd;
				padding: 5px;
				font-size: 14px;
			}
			
			input {
				padding: 5px;
				width: 50px;
				text-align: center;
				font-size: 14px;
			}
			
			span {
				color: #9FA2B7;
				white-space: nowrap;
			}
		}
	}
	
	.pageInfo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 10px 0;
	}
	
	nav {
		flex: 1;
		display: flex;
		justify-content: center;
		gap: 10px;
		
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;
			background-color: var(--brand-blue);
			color: white;
			transition: background-color .3s, color .3s;
			cursor: pointer;
			
			&:hover {
				background-color: var(--brand-orange);
				color: var(--brand-blue);
			}
		}
	}
}

.table_search {
	padding: 0 15px;
}

.wrapper {
	background-color: white;
	padding: 15px;
	margin-bottom: 20px;
	
	input[name=search] {
		margin-bottom: 10px;
	}
	
	.zorunlu_yazi {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 5px;
		
		span {
			display: flex;
			align-items: center;
			color: #e74c3c;
		}
	}
	
	.panelTitle {
		margin-top: 20px;
	}
	
	fieldset {
		border: 1px solid #aaaaaa;
		padding: 20px 10px;
		margin: 10px 0;
	}
}

.container {
	background-color: white;
	overflow: auto;
}

.panelTitle {
	background-color: var(--brand-blue);
	color: white;
	padding: 20px;
	gap: 5px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	
	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 15px;
		background-color: var(--brand-blue);
		border: 2px solid var(--brand-orange);
		color: white;
		border-radius: 10px;
		gap: 5px;
		cursor: pointer;
		transition: background-color .3s, color .3s;
		margin-right: 10px;
		
		&:hover {
			background-color: var(--brand-orange);
			color: var(--brand-blue);
		}
	}
	
	svg {
		color: var(--brand-orange);
		font-size: 20px;
	}
}

.products {
	padding: 15px;
	cursor: default;
	
	.product {
		overflow: hidden;
		margin-bottom: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		
		&:hover {
			.productImage {
				.productButtons {
					opacity: 1;
				}
			}
		}
		
		.productHeader {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			
			h3 {
				text-align: center;
				font-size: 20px;
				color: var(--brand-orange);
				font-weight: bold;
			}
		}
		
		.productImage {
			position: relative;
			display: flex;
			align-items: center;
			flex: 1;
			overflow: hidden;
			
			img {
				width: 100%;
				object-fit: cover;
				z-index: 1;
				border-radius: 10px;
			}
			
			.productButtons {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				display: flex;
				flex-direction: column;
				gap: 10px;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				transition: opacity .3s;
				opacity: 0;
				
				a, button {
					height: 40px;
					padding: 0 10px;
					border-radius: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					cursor: pointer;
					gap: 10px;
					font-size: 15px;
					transition: transform .3s;
					
					&:nth-child(n+1) {
						background-color: var(--brand-orange);
						
						svg {
							color: var(--brand-blue);
							font-size: 25px;
						}
					}
					
					&:nth-child(n+2) {
						background-color: var(--brand-blue);
						
						svg {
							color: var(--brand-orange);
							font-size: 25px;
						}
					}
					
					&:hover {
						transform: scale(1.05);
					}
					
					
				}
			}
		}
		
		.productPrice {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 10px;
			
			.productPriceOld {
				color: #44bd32;
				font-weight: bold;
				
				span {
					text-decoration: line-through;
					color: #444444;
					font-weight: normal;
				}
			}
			
			.productPriceNew {
				font-weight: bold;
				font-size: 25px;
				color: var(--brand-orange);
			}
		}
	}
	
	.moreProducts {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		
		a, button {
			height: 40px;
			padding: 0 15px;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--brand-blue);
			color: #FFFFFF;
			cursor: pointer;
			gap: 10px;
			transition: transform .3s;
			
			&:hover {
				transform: scale(1.2);
			}
			
			svg {
				color: var(--brand-orange);
				font-size: 25px;
			}
		}
	}
}

.profile {
	padding: 25px 0;
	
	h1 {
		font-size: 30px;
		padding: 10px;
		color: #222222;
	}
	
	.addresses {
		padding: 20px;
		border: 1px solid #dddddd;
		border-radius: 10px;
		
		h2 {
			font-size: 25px;
			color: #444444;
			text-align: center;
			margin-bottom: 20px;
		}
		
		.noAddress {
			h3 {
				text-align: center;
				padding: 20px;
				color: gray;
			}
		}
		
		> button {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--brand-backgorund);
			color: #FFFFFF;
			padding: 0 15px;
			border-radius: 20px;
			cursor: pointer;
		}
		
		section {
			display: flex;
			gap: 15px;
			justify-content: center;
			flex-wrap: wrap;
			
			.address {
				border-radius: 10px;
				border: 1px solid #dddddd;
				width: calc(100% / 4 - 15px);
				overflow: hidden;
				
				h3 {
					font-size: 25px;
					color: #222222;
					padding: 20px 10px 10px;
				}
				
				p {
					color: #444444;
					padding: 0 15px 25px 15px;
				}
				
				.buttons {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 40px;
					
					button {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: var(--brand-backgorund);
						color: #FFFFFF;
						width: 100%;
						height: 100%;
						font-size: 16px;
						gap: 5px;
						transition: background-color .3s;
						cursor: pointer;
						
						svg {
							font-size: 23px;
						}
						
						&:hover {
							background-color: var(--brand-orange);
						}
					}
				}
			}
		}
	}
}

.cart {
	padding: 20px 0;
	
	.noProducts {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 30px;
		
		p {
			font-size: 18px;
			color: gray;
			text-align: center;
		}
		
		a {
			background-color: var(--brand-orange);
			color: #FFF;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 25px;
			border-radius: 10px;
			transition: background-color .3s;
			
			&:hover {
				background-color: var(--brand-blue);
			}
		}
	}
	
	h1 {
		font-size: 30px;
		padding: 20px 5px;
	}
	
	.total {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		border: 1px solid #ddd;
		gap: 7px;
		overflow: hidden;
		
		h2 {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 20px;
			background-color: var(--brand-orange);
			color: #FFFFFF;
			width: 100%;
		}
		
		section {
			width: 100%;
			padding: 20px;
			
			span {
				font-size: 35px;
				padding: 20px 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			button {
				width: 100%;
				height: 60px;
				border-radius: 5px;
				background-color: var(--brand-blue);
				font-size: 25px;
				color: #FFFFFF;
				cursor: pointer;
				transition: background-color .3s;
				
				&:hover {
					background-color: var(--brand-orange);
				}
			}
		}
	}
	
	.product {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #ddd;
		padding: 20px;
		border-radius: 10px;
		margin-bottom: 20px;
		flex-wrap: wrap;
		gap: 20px;
		
		.productImage {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			
			img {
				width: 100px;
			}
		}
		
		.productInfo {
			flex: 3;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
			h3 {
				a {
					color: #444444;
					font-weight: bold;
					font-size: 18px;
					cursor: pointer;
					transition: color .3s;
					display: block;
					width: 100%;
					
					&:hover {
						color: #222222;
					}
				}
			}
			
			.productPrice {
				display: flex;
				justify-content: center;
				flex-direction: column;
				
				.productPriceOld {
					color: #44bd32;
					font-weight: bold;
					font-size: 14px;
					white-space: nowrap;
					
					span {
						text-decoration: line-through;
						color: #444444;
						font-weight: normal;
					}
				}
				
				.productPriceNew {
					font-weight: bold;
					font-size: 30px;
					color: var(--brand-orange);
					white-space: nowrap;
				}
			}
		}
		
		.productControl {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 2;
			
			.productButtons {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				gap: 10px;
				
				> button {
					height: 50px;
					padding: 0 25px;
					border-radius: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					cursor: pointer;
					gap: 10px;
					font-size: 15px;
					transition: background-color .3s;
					background-color: var(--brand-orange);
					
					svg {
						color: var(--brand-blue);
						transition: color .3s;
						font-size: 25px;
					}
					
					&:hover {
						background-color: var(--brand-blue);
						
						svg {
							color: var(--brand-orange);
						}
					}
					
					
				}
				
				.productAmountController {
					display: flex;
					
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					
					/* Firefox */
					input[type=number] {
						-moz-appearance: textfield;
						text-align: center;
						height: 30px;
						padding: 0 10px;
						border: 1px solid #ddd;
						border-radius: 15px;
						position: relative;
						z-index: 1;
					}
					
					.controller {
						display: flex;
						flex-direction: column;
						
						button {
							height: 15px;
							padding: 0 10px 0 30px;
							transform: translateX(-20px);
							border-bottom: 1px solid #FFFFFF;
							background-color: var(--brand-orange);
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 12px;
							transition: background-color .3s, color .3s;
							
							&:first-child {
								border-top-right-radius: 5px;
							}
							
							&:last-child {
								border-bottom-right-radius: 5px;
							}
							
							&:hover {
								background-color: var(--brand-blue);
								color: var(--brand-orange);
								cursor: pointer;
							}
						}
					}
				}
				
				.deleteProduct {
					button {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 25px;
						color: gray;
						background-color: transparent;
						padding: 5px;
						transition: color .3s;
						cursor: pointer;
						
						&:hover {
							color: var(--brand-orange);
						}
					}
				}
			}
		}
	}
}

.cartCheck {
	h1 {
		text-align: center;
		font-size: 25px;
		padding: 10px;
		color: #444444;
	}
	
	.addresses {
		padding: 20px;
		border: 1px solid #dddddd;
		border-radius: 10px;
		
		h2 {
			font-size: 25px;
			color: #444444;
			text-align: center;
			margin-bottom: 20px;
		}
		
		.noAddress {
			h3 {
				text-align: center;
				padding: 20px;
				color: gray;
			}
		}
		
		> button {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--brand-backgorund);
			color: #FFFFFF;
			padding: 0 15px;
			border-radius: 20px;
			cursor: pointer;
		}
		
		section {
			display: flex;
			gap: 15px;
			justify-content: center;
			flex-wrap: wrap;
			
			label {
				width: calc(100% / 4 - 15px);
				cursor: pointer;
				
				input {
					display: none;
					
					&:checked {
						& + .address {
							background-color: #444444;
							
							svg {
								visibility: visible;
								opacity: 1;
							}
							
							h3 {
								color: #FFFFFF;
							}
							
							p {
								color: #DDDDDD;
							}
						}
					}
				}
				
				.address {
					transition: background-color .3s;
					position: relative;
					border-radius: 10px;
					border: 1px solid #dddddd;
					
					svg {
						visibility: visible;
						opacity: 0;
						transition: visibility .3s, opacity .3s;
						position: absolute;
						color: var(--brand-backgorund);
						right: 0;
						top: 0;
						width: 30px;
						height: 30px;
						border-radius: 15px;
						background-color: #FFFFFF;
						transform: translate(10px, -10px);
						
					}
					
					h3 {
						font-size: 25px;
						color: #222222;
						padding: 20px 10px 10px;
						transition: color .3s;
					}
					
					p {
						color: #444444;
						padding: 0 15px 25px 15px;
						transition: color .3s;
						
					}
					
					.buttons {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 40px;
						
						button {
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: var(--brand-backgorund);
							color: #FFFFFF;
							width: 100%;
							height: 100%;
							font-size: 16px;
							gap: 5px;
							transition: background-color .3s;
							cursor: pointer;
							
							svg {
								font-size: 23px;
							}
							
							&:hover {
								background-color: var(--brand-orange);
							}
						}
					}
				}
			}
		}
	}
}

.productContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 190px);
	
	.product {
		display: flex;
		width: 100%;
		max-width: 1200px;
		
		.productImage {
			flex: 1;
			
			img {
				width: 100%;
				border-radius: 10px;
			}
		}
		
		.productInfo {
			padding: 20px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			flex: 1;
			
			h2 {
				color: #484848;
				font-size: 40px;
				font-weight: 600;
			}
			
			p {
			
			}
			
			.productPrice {
				display: flex;
				justify-content: center;
				flex-direction: column;
				
				.productPriceOld {
					color: #44bd32;
					font-weight: bold;
					font-size: 20px;
					
					span {
						text-decoration: line-through;
						color: #444444;
						font-weight: normal;
					}
				}
				
				.productPriceNew {
					font-weight: bold;
					font-size: 50px;
					color: var(--brand-orange);
				}
			}
			
			.productButtons {
				display: flex;
				
				> button {
					height: 50px;
					padding: 0 25px;
					border-radius: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #FFFFFF;
					cursor: pointer;
					gap: 10px;
					font-size: 15px;
					transition: background-color .3s;
					background-color: var(--brand-orange);
					
					svg {
						color: var(--brand-blue);
						transition: color .3s;
						font-size: 25px;
					}
					
					&:hover {
						background-color: var(--brand-blue);
						
						svg {
							color: var(--brand-orange);
						}
					}
					
					
				}
				
				section {
					display: flex;
					
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					
					/* Firefox */
					input[type=number] {
						-moz-appearance: textfield;
						text-align: center;
						height: 50px;
						padding: 0 25px;
						border: 1px solid #ddd;
						border-radius: 25px;
						position: relative;
						z-index: 1;
					}
					
					.controller {
						display: flex;
						flex-direction: column;
						
						button {
							height: 25px;
							padding: 0 10px 0 30px;
							transform: translateX(-20px);
							border-bottom: 1px solid #FFFFFF;
							background-color: var(--brand-orange);
							color: #FFFFFF;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 14px;
							transition: background-color .3s, color .3s;
							
							&:first-child {
								border-top-right-radius: 5px;
							}
							
							&:last-child {
								border-bottom-right-radius: 5px;
							}
							
							&:hover {
								background-color: var(--brand-blue);
								color: var(--brand-orange);
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
}

.addDirectory {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
	position: relative;
	
	h2 {
		margin-bottom: 40px;
		text-align: center;
		font-size: 25px;
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
	}
	
	a {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 35px;
		padding: 0 35px;
		gap: 10px;
		font-size: 20px;
		cursor: pointer;
		
		svg {
			font-size: 35px;
		}
	}
}

.swiper {
	height: calc(100vh - 190px);
	
	.swiper-wrapper {
		.swiper-slide {
			position: relative;
			
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background-color: rgba(0, 0, 0, 0.3);
			}
		}
	}
}

.noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.editArea {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	background-color: rgba(black, 0.3);
	color: white;
	font-size: 50px;
	cursor: pointer;
	transition: background-color .3s;
	min-height: 50px;
	
	svg {
		transition: transform .3s;
	}
	
	&:hover {
		background-color: rgba(black, 0.5);
		
		svg {
			transform: scale(1.2);
		}
	}
}

.security {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	
	h2 {
		font-size: 20px;
		font-weight: bold;
	}
	
	section {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		
		button {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--brand-backgorund);
			color: var(--brand-color);
			border: none;
			height: 40px;
			cursor: pointer;
			transition: background-color .3s;
			
			&:hover {
				background-color: #0b72de;
			}
			
			&.active {
				background-color: #ba1616;
			}
			
			
		}
	}
}

.wifiQrCode {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 20px;
	
	.code {
		padding: 4px 4px 0 4px;
		background-color: white;
	}
}

label {
	.input_title {
		margin-top: 5px;
		padding: 5px;
		font-size: 14px;
		display: flex;
		align-items: flex-start;
		gap: 3px;
		
		span {
			display: flex;
			align-items: center;
			font-size: 8px;
			color: #d31d0b;
		}
	}
	
	.input_area {
		position: relative;
		font-size: 12px;
		
		.input {
			width: 100%;
			padding: 0 5px 0 40px;
			outline: 0;
			border-radius: 5px;
			border: 1px solid #888;
			-webkit-appearance: none;
			font-size: 12px;
			display: flex;
			align-items: center;
			position: relative;
			resize: vertical;
		}
		
		textarea.input {
			padding: 10px 10px 10px 40px;
		}
		
		> div {
			> div {
				cursor: pointer;
				
				> div {
					padding: 0;
					
					svg {
						cursor: pointer;
					}
				}
				
				&:nth-child(3) {
					padding-left: 40px;
				}
			}
		}
		
		i {
			position: absolute;
			bottom: 0;
			left: 0;
			border-right: 1px solid #ddd;
			transition: color .3s;
			height: 100%;
			width: 35px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			color: #888;
			font-size: 20px;
		}
	}
}

label .input_area input:focus + i, label .input_area select:focus + i label, .input_area textarea:focus + i, .input_area select:focus + i {
	color: #FC5E28;
}

label.check {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	
	input {
		display: none;
		
		&:checked {
			~ div {
				border: 1px solid var(--brand-orange);
				
				span {
					background-color: var(--brand-orange);
				}
			}
		}
	}
	
	div {
		height: 25px;
		width: 25px;
		position: relative;
		border-radius: 5px;
		border: 1px solid gray;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: .3s border-color;
		cursor: pointer;
		
		&:hover {
			border: 1px solid var(--brand-orange);
		}
		
		span {
			display: block;
			height: 15px;
			width: 15px;
			border-radius: 5px;
			transition: .3s background-color;
			background-color: #bbbbbb;
		}
	}
}

::-webkit-file-upload-button {
	height: 100%;
	margin: 0 5px 0 -5px;
	border: none;
	background-color: var(--brand-backgorund);
	color: var(--brand-color);
	padding: 0 10px;
	cursor: pointer;
	transition: .3s color;
	
	&:hover {
		color: var(--brand-color);
	}
}

.goCart {
	color: var(--brand-orange);
	margin: 5px;
	cursor: pointer;
	transition: color .3s;
	background-color: transparent;
	
	&:hover {
		color: var(--brand-blue);
	}
}

.card {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	min-height: 500px;
	background-color: white;
	padding-bottom: 100px;
	
	.cardHeader {
		position: relative;
		
		.actionBar {
			position: absolute;
			height: 50px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 15px;
			z-index: 3;
			
			section {
				
				display: flex;
				gap: 20px;
				
				button, a {
					background: transparent;
					border: none;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					cursor: pointer;
					transition: color .3s;
					font-size: 40px;
					z-index: 1;
					
					&:hover {
						color: lightgray;
					}
				}
			}
		}
		
		> section {
			height: 350px;
			width: 100%;
			position: relative;
			background-size: cover;
			
			.businessLogoWrapper {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(black, 0.3);
				
				.businessLogo {
					height: 100px;
					width: 100px;
					overflow: hidden;
					border-radius: 50px;
					transform: translateY(-50%);
					background: url("https://chint.com.tr/wp-content/uploads/2022/03/urun-noimage.jpg");
					background-size: cover;
					
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}
		}
		
		.profile {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			transform: translateY(-100px);
			position: relative;
			z-index: 3;
			
			.photo {
				width: 200px;
				height: 200px;
				border-radius: 100px;
				overflow: hidden;
				position: relative;
				z-index: 3;
				background: url("https://chint.com.tr/wp-content/uploads/2022/03/urun-noimage.jpg");
				background-size: cover;
				
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
			section {
				position: relative;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				
				.details {
					width: 100%;
					padding: 10px;
					
					ul {
						display: flex;
						flex-direction: column;
						gap: 20px;
						
						li {
							display: flex;
							align-items: center;
							gap: 10px;
							color: inherit;
							
							svg {
								font-size: 30px;
								opacity: 0.5;
							}
							
							span {
								transition: transform .3s;
								color: inherit;
								cursor: default;
								font-size: 14px;
								
								a {
									color: inherit;
									cursor: pointer;
								}
								
								&:hover {
									transform: scale(1.01);
								}
								
								
							}
						}
					}
				}
				
				h2 {
					font-size: 30px;
					display: flex;
					gap: 5px;
					align-items: center;
					
					button {
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
						cursor: pointer;
						font-size: 25px;
						color: inherit;
						background-color: transparent;
					}
				}
				
				i {
					font-size: 16px;
				}
				
				b {
					font-size: 14px;
				}
			}
		}
	}
	
	.cardBody {
		
		.infoBox {
			padding: 50px 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			min-height: 350px;
			gap: 30px;
			
			h2 {
				margin-bottom: 40px;
				text-align: center;
				font-size: 25px;
				display: flex;
				gap: 10px;
				justify-content: center;
				align-items: center;
				
				button {
					display: flex;
					align-items: center;
					justify-content: center;
					border: none;
					cursor: pointer;
					font-size: 25px;
					color: inherit;
					background-color: transparent;
				}
			}
			
			section {
				display: flex;
				justify-content: space-evenly;
				flex-wrap: wrap;
				row-gap: 50px;
				
				.button {
					width: calc(100% / 4);
					
					button, a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 70px;
						height: 70px;
						border-radius: 50px;
						border: none;
						font-size: 35px;
						margin: 0 auto;
						cursor: pointer;
					}
				}
				
			}
			
			.addButton {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 150px;
				
				button, a {
					display: flex;
					align-items: center;
					justify-content: center;
					color: gray;
					width: 70px;
					height: 70px;
					border-radius: 50px;
					background-color: transparent;
					font-size: 35px;
					margin: 0 auto;
					cursor: pointer;
					border: 2px solid white;
					transition: transform .3s;
					
					&:hover {
						transform: scale(1.1);
					}
				}
				
				
			}
		}
		
		.imageBox {
			position: relative;
			
			.header {
				display: flex;
				align-items: center;
				justify-content: center;
				
				h2 {
					text-align: center;
					font-size: 24px;
					padding: 20px 0;
				}
			}
			
			.body {
				padding: 0 20px;
				
				img {
					width: 100%;
				}
				
				p {
					text-align: center;
					font-size: 20px;
					padding: 20px 5px;
				}
			}
			
			.footer {
				padding: 20px 30px;
				display: flex;
				justify-content: flex-end;
				font-size: 18px;
			}
		}
		
		.videoBox {
			position: relative;
			
			.header {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			.body {
				padding: 20px 20px 0 20px;
				
				.video {
					height: 0;
					padding-top: calc(100% * 9 / 16);
					position: relative;
					
					iframe {
						position: absolute;
						width: 100%;
						height: 100%;
						inset: 0;
					}
				}
				
				h2 {
					font-size: 24px;
					padding: 10px;
				}
				
				p {
					font-size: 20px;
					padding: 10px;
				}
			}
			
			.footer {
				padding: 20px 30px;
				font-size: 18px;
				display: flex;
				justify-content: flex-end;
			}
		}
		
		.spotifyBox {
			position: relative;
			
			.playlist {
				height: 0;
				padding-top: 80%;
				position: relative;
				
				iframe {
					position: absolute;
					width: 100%;
					height: 100%;
					inset: 0;
				}
			}
		}
		
		.addComponent {
			padding: 20px;
			
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 200px;
				border-radius: 5px;
				border: 2px dashed gray;
				color: gray;
				font-size: 100px;
				background-color: transparent;
				outline: none;
				transition: transform .3s;
				cursor: pointer;
				
				&:hover {
					transform: scale(1.05);
				}
			}
		}
	}
	
	.languageSelect {
		padding: 5px;
		color: #000000;
		position: relative;
		z-index: 10;
	}
}

.button_area {
	gap: 10px;
	flex-wrap: wrap-reverse;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-top: auto;
	
	.button {
		flex: 1;
		position: relative;
		border-radius: 5px;
		overflow: hidden;
		
		.loading {
			position: absolute;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--brand-backgorund);
			
			img {
				height: 90%;
			}
		}
		
		button, a {
			color: var(--brand-color);
			padding: 10px 20px;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			gap: 5px;
			transition: .3s background-color, .3s color;
			border: none;
			outline: none !important;
			background-color: var(--brand-backgorund);
			white-space: nowrap;
			cursor: pointer;
			font-weight: 600;
			
			svg {
				color: var(--brand-color);
				font-size: 20px;
				transition: color .3s;
				flex-shrink: 0;
			}
			
			&:hover {
				background-color: var(--brand-color);
				color: var(--brand-backgorund);
				
				svg {
					color: var(--brand-backgorund);
				}
			}
		}
	}
}

.MenuWrapper {
	position: fixed;
	inset: 0;
	z-index: 10;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	
	.MenuCloseBack {
		position: fixed;
		inset: 0;
		background-color: rgba(black, 0.5);
	}
	
	.Menu {
		width: 100%;
		max-width: 600px;
		background-color: white;
		padding: 15px;
		min-height: 40vh;
		max-height: 80vh;
		border-radius: 10px;
		position: relative;
		z-index: 11;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow-y: auto;
		
		&::-webkit-scrollbar {
			display: none;
		}
		
		form {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		
		h2 {
			padding: 10px;
			color: #444444;
		}
	}
}

footer {
	border-top: 5px solid var(--brand-backgorund);
	padding: 20px 0 50px 0;
	
	h2 {
		font-size: 24px;
		padding: 10px 0;
		color: #444444;
		font-weight: bold;
	}
	
	p {
		font-size: 18px;
		color: #444444;
	}
	
	section {
		display: flex;
		flex-direction: column;
		gap: 5px;
		
		a, button {
			font-size: 18px;
			color: #444444;
		}
	}
	
	.socialMedia {
		display: flex;
		gap: 15px;
		
		a {
			font-size: 20px;
			height: 40px;
			width: 40px;
			background-color: var(--brand-backgorund);
			display: flex;
			align-items: center;
			justify-content: center;
			color: #FFFFFF;
			border-radius: 20px;
			transition: transform .3s;
			
			&:hover {
				transform: scale(1.07);
			}
		}
	}
}

@media only screen and (max-width: 1250px) {
	header {
		&.mainHeader {
			padding: 0 20px;
			
			> ul {
				height: 120px;
				
				&:nth-child(1) {
					li {
						a {
							img {
								width: 100px;
								height: 100px;
							}
						}
					}
				}
				
				li {
					a, button {
						font-size: 16px;
						
						svg {
							font-size: 22px;
						}
						
						span {
							svg {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
	.swiper {
		height: calc(100vh - 160px);
		
	}
}

@media only screen and (max-width: 1100px) {
	footer {
		padding-bottom: 100px;
	}
}